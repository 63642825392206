import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb olor a rave que presenta un capell cònic rosat normalment o lilós de 6-7 cm de diàmetre, campanulat i esdevé pla, amb un mamelló més o menys pronunciat. Les nombroses làmines són de color blanquinós i les espores també blanques en massa, el·líptiques, de 5-9 x 3-4 micres. El peu, amb tons rosats, és llarg i fistulós.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      